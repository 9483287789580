import React, { memo, useState, useEffect } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";

import { createModuleStyleExtractor } from "../../../utils/css";
import { Button } from "../../atoms/Button";
import Card from "../../atoms/Card/Card";
import { Modal } from "../../atoms/Modal/Modal";
import { ModelHeader } from "../../atoms/Modal/ModelHeader/ModelHeader";
import { ModalBody } from "../../atoms/Modal/ModalBody/ModalBody";
import MuiTextbox from "../../atoms/MuiTextbox/MuiTextbox";
import CustomDropzone from "../../atoms/CustomDropzone/CustomDropzone";
import styles from "./ContactCareerCenter.module.scss";
import { initialState } from "../../../redux/reducers/chat/state";
import { contactUs } from "../../../api/helpcenter";
import toastify from "../../../utils/toast";
import { validateString } from "../../../utils/validations";
import FormattedPhoneNumber from "../../atoms/FormattedPhoneNumber/FormattedPhoneNumber";

const cx = createModuleStyleExtractor(styles);

const INITIAL_STATE = {
  apiLoading: false,
  showModal: false,
};

const ContactCareerCenter = ({ admissionCenterDetails }) => {
  const [state, setState] = useState(initialState);

  const { id } = useSelector((state) => state.userState.selectedSchool);

  // Form Submission
  const handleSave = async () => {
    setState((current) => ({
      ...current,
      apiLoading: true,
    }));
    const formData = new FormData();
    formik.values.image &&
      formData.append("contact_us[issue_image]", formik.values.image);
    formData.append("contact_us[comment]", formik.values.comment);
    formData.append("contact_us[contact_career_center]", true);
    formData.append("school_id", id);

    try {
      const response = await contactUs(formData);
      if (response.success) {
        toastify(response.message);
        handleCancel();
      } else {
        toastify(response.message);
        setState((current) => ({
          ...current,
          apiLoading: false,
        }));
      }
    } catch (error) {
      console.error(error);
      toastify("Oops! something went wrong. Please try again");
      setState((current) => ({
        ...current,
        apiLoading: false,
      }));
    }
  };

  // Validation
  const validate = (values) => {
    const errors = {};
    // Inquiry
    if (values.comment === "") {
      errors.comment = "Please tell us more about your inquiry";
    }
    return errors;
  };

  // Formik Hook
  const formik = useFormik({
    initialValues: {
      comment: "",
      image: "",
    },
    validate,
    onSubmit: handleSave,
  });

  const handleFileUpload = (file) => {
    formik.setFieldValue("image", file[0]);
  };

  const handleCancel = () => {
    setState((current) => ({
      ...INITIAL_STATE,
      data: current.data,
    }));
    formik.values.comment = "";
    formik.values.image = "";
    formik.setErrors({});
  };

  // Reset for values upon close
  useEffect(() => {
    if (!state.showModal) {
      handleCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.showModal]);

  if (
    validateString(admissionCenterDetails.phone_numbers) &&
    validateString(admissionCenterDetails.career_center_email) &&
    validateString(admissionCenterDetails.availability)
  )
    return null;

  return (
    <>
      <div className={cx("m-contact-career")}>
        <Card>
          <div className={cx("m-contact-career__title")}>
            <h3>Contact your Admissions Office</h3>
          </div>
          <div className={cx("m-contact-career__subtitle")}>
            <span>Get answers on school specific questions</span>
          </div>
          {!validateString(admissionCenterDetails.phone_numbers) && (
            <div className={cx("m-contact-career__detail")}>
              <p className={cx("m-contact-career__detail__label")}>Phone</p>
              <p className={cx("m-contact-career__detail__value")}>
                <FormattedPhoneNumber
                  number={
                    admissionCenterDetails.country_code +
                    admissionCenterDetails.phone_numbers
                  }
                />
              </p>
            </div>
          )}
          {!validateString(admissionCenterDetails.career_center_email) && (
            <div className={cx("m-contact-career__detail")}>
              <p className={cx("m-contact-career__detail__label")}>Email</p>
              <p className={cx("m-contact-career__detail__value")}>
                {admissionCenterDetails.career_center_email}
              </p>
            </div>
          )}
          {!validateString(admissionCenterDetails.availability) && (
            <div className={cx("m-contact-career__detail")}>
              <p className={cx("m-contact-career__detail__label")}>Hours</p>
              <p className={cx("m-contact-career__detail__value")}>
                {admissionCenterDetails.availability}
              </p>
            </div>
          )}
          {!validateString(admissionCenterDetails.career_center_email) && (
            <div className={cx("m-contact-career__button")}>
              <Button
                onClick={() =>
                  setState((current) => ({ ...current, showModal: true }))
                }
              >
                Contact school
              </Button>
            </div>
          )}
        </Card>
      </div>
      {/* Modal */}
      <Modal
        show={state.showModal}
        onClose={() =>
          setState((current) => ({ ...current, showModal: false }))
        }
        centerAlign={false}
      >
        <ModelHeader title={"Contact your Admissions Office"} />
        <ModalBody>
          <div className={cx("m-contact-career-modal-body")}>
            <div className={cx("m-contact-career-modal-body__description")}>
              {!validateString(admissionCenterDetails.availability) && (
                <>
                  <p>Availability:</p>
                  <p>{admissionCenterDetails.availability}</p>
                  <br />
                </>
              )}
              {!validateString(admissionCenterDetails.phone_numbers) && (
                <>
                  <p>Phone number:</p>
                  <p>
                    <FormattedPhoneNumber
                      number={
                        admissionCenterDetails.country_code +
                        admissionCenterDetails.phone_numbers
                      }
                    />
                  </p>
                  <br />
                </>
              )}
              {!validateString(admissionCenterDetails.career_center_email) && (
                <>
                  <p>Email:</p>
                  <p>{admissionCenterDetails.career_center_email}</p>
                </>
              )}
            </div>
            <div className={cx("m-contact-career-modal-body__form")}>
              <form onSubmit={formik.handleSubmit}>
                <div className={cx("m-contact-career-modal-body__form__body")}>
                  <div
                    className={cx(
                      "m-contact-career-modal-body__form__body__comment"
                    )}
                  >
                    <MuiTextbox
                      name="comment"
                      onBlur={formik.handleBlur}
                      maxLength={500}
                      label="Send an email*"
                      value={formik.values.comment}
                      onChange={(event) =>
                        formik.setFieldValue("comment", event.target.value)
                      }
                      isMultiline
                      rows={1}
                      placeholder="Describe the inquiry here."
                      error={
                        formik.touched.comment && formik.errors.comment
                          ? formik.errors.comment
                          : ""
                      }
                    />
                  </div>
                  <div
                    className={cx(
                      "m-contact-career-modal-body__form__body__file_upload"
                    )}
                  >
                    <CustomDropzone
                      handleUploadFile={handleFileUpload}
                      textOne="Upload an Image"
                      textTwo="Upload an Image"
                    />
                    {formik.values.image && <p>{formik.values.image.path}</p>}
                  </div>
                  <div
                    className={cx(
                      "m-contact-career-modal-body__form__body__submit_button"
                    )}
                  >
                    <Button type="submit" isSubmitting={state.apiLoading}>
                      Submit
                    </Button>
                  </div>
                  <div
                    className={cx(
                      "m-contact-career-modal-body__form__body__cancel"
                    )}
                  >
                    <span onClick={handleCancel}>Cancel</span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
export default memo(ContactCareerCenter);
