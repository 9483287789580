import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, ClickAwayListener } from "@mui/material";
import { ShimmerSocialPost } from "react-shimmer-effects";
import InfiniteScroll from "react-infinite-scroll-component";

import { createModuleStyleExtractor } from "../../../../utils/css";
import styles from "./TopicMiddle.module.scss";
import { acceptDeclineTopicRequestAPI } from "../../../../api/community";
import { managePendingRequest } from "../../../../redux/reducers/communityReducer";
import { Button } from "../../../atoms/Button";
import PendingRequest from "../PendingRequest/PendingRequest";
import CreatePost from "../CreatePost/CreatePost";
import TopicPost from "../TopicPost/TopicPost";

import EmptyListIcon from "../../../../assets/svg/empty-list-community.svg";

const cx = createModuleStyleExtractor(styles);

export default function TopicMiddle({
  setActiveUser = () => {},
  setShowUserPopup = () => {},
  callGetTopicFeedAPI = () => {},
  callGetTopicDetailsAPI = () => {},
  callGetSavedTopicPostsAPI = () => {},
}) {
  const {
    activeTopicId,
    topicPosts,
    activeTopic,
    loadingTopicPosts,
    topicPendingRequest,
  } = useSelector((state) => state.communityState);
  const { user = {}, permissions = {} } = useSelector(
    (state) => state.userState
  );
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(false);

  const acceptRejectRequest = async (data = {}) => {
    const response = await acceptDeclineTopicRequestAPI(data);
    if (response.success && response.data) {
      dispatch(managePendingRequest(response.data));
    }
  };

  const topicName = useMemo(
    () =>
      topicPosts?.type === "feed"
        ? "Community feed"
        : topicPosts?.type === "topic"
        ? activeTopic?.topic_name
        : topicPosts?.type === "all-saved" || topicPosts?.type === "topic-saved"
        ? "Saved posts"
        : "",
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [topicPosts?.type]
  );

  const handlePendingReq = () => {
    setAnchorEl(!anchorEl);
  };

  const fetchMorePosts = () => {
    if (topicPosts?.type === "topic") {
      callGetTopicDetailsAPI(activeTopicId, topicPosts?.current_page + 1);
    } else if (topicPosts?.type === "feed") {
      callGetTopicFeedAPI(topicPosts?.current_page + 1);
    } else if (topicPosts.type === "all-saved") {
      callGetSavedTopicPostsAPI(null, topicPosts?.current_page + 1);
    } else if (topicPosts.type === "topic-saved") {
      callGetSavedTopicPostsAPI(activeTopicId, topicPosts?.current_page + 1);
    }
  };

  return (
    <div className={cx("middle-section")}>
      <div className={cx("title-withbutton")}>
        {!loadingTopicPosts && (
          <>
            <Tooltip title={topicName} placement="bottom">
              <h2 className={cx("title-primary")}>{topicName}</h2>
            </Tooltip>
            {activeTopicId &&
              topicPendingRequest?.length > 0 &&
              activeTopic?.created_by_id === user.id && (
                <div className="pending-request">
                  <Button
                    width="175"
                    height="30"
                    onClick={handlePendingReq}
                  >{`${topicPendingRequest?.length} Pending requests`}</Button>
                  {anchorEl && (
                    <ClickAwayListener onClickAway={() => setAnchorEl(false)}>
                      <PendingRequest
                        list={topicPendingRequest}
                        acceptRejectRequest={acceptRejectRequest}
                      />
                    </ClickAwayListener>
                  )}
                </div>
              )}
          </>
        )}
      </div>
      <div className={cx("posts")}>
        {!loadingTopicPosts &&
          activeTopicId &&
          topicPosts?.type === "topic" &&
          !(
            permissions?.topic_posts === false &&
            (user?.user_type === "prospect" || user?.ambassador === true)
          ) && <CreatePost callGetTopicDetailsAPI={callGetTopicDetailsAPI} />}
        {loadingTopicPosts ? (
          <>
            {Array.from(Array(3).keys()).map((item, index) => (
              <ShimmerSocialPost type="image" key={index} />
            ))}
          </>
        ) : !loadingTopicPosts && topicPosts?.posts?.length === 0 ? (
          <div className={cx("posts__empty")}>
            <img alt="empty-list" src={EmptyListIcon} />
            <h3>Looks like this Community does not have any posts</h3>
          </div>
        ) : (
          <div className="topic-post-wrapper">
            <InfiniteScroll
              dataLength={topicPosts?.posts?.length || 0}
              next={fetchMorePosts}
              hasMore={
                topicPosts?.current_page !== topicPosts?.total_pages &&
                topicPosts?.total_pages > 0
              }
              loader={Array.from(Array(3).keys()).map((item, index) => (
                <ShimmerSocialPost type="image" key={index} />
              ))}
              style={{ overflow: "none" }}
            >
              <>
                {topicPosts?.posts?.map((topic, index) => {
                  return (
                    <TopicPost
                      key={index}
                      topic={topic}
                      setShowUserPopup={setShowUserPopup}
                      setActiveUser={setActiveUser}
                      callGetTopicDetailsAPI={callGetTopicDetailsAPI}
                    />
                  );
                })}
              </>
            </InfiniteScroll>
          </div>
        )}
      </div>
    </div>
  );
}
